<template>
  <div class="grid">
    <van-row>
      <van-col span="6">
        <div class="item" @click="$router.push('/edit')">
          <img width="40vw" src="@/assets/userinfo2.png" /><br />个人
        </div>
      </van-col>
      <van-col span="6">
        <div class="item" @click="toclockinindex">
          <img width="40vw" src="@/assets/certificate.png" /><br />打卡
        </div>
      </van-col>
      <van-col span="6">
        <div class="item" @click="$router.push('/systeminfo')">
          <img width="40vw" src="@/assets/hzh.png" /><br />统计
        </div>
      </van-col>
      <van-col span="6">
        <div class="item">
          <img
            width="40vw"
            src="@/assets/exit.png"
            @click="toLoginout"
          /><br />注销
        </div>
      </van-col>
    </van-row>

    <div>
      <van-cell
        :title="title"
        is-link
        class="vancell"
        v-if="isJoinclass.classid > 0 && !isJoinclass.isgrauted"
        @click="toCourses"
      />
      <van-cell
        :title="title"
        is-link
        class="vancell"
        v-else
        @click="joinclass"
      />
      <!--
      <div class="systeminfo" @click="toSystemINfo()">系统信息</div>
      <div
        v-if="isJoinclass.classid > 0 && !isJoinclass.isgrauted"
        class="systeminfo"
        @click="toCourses()"
      >
        您已报名{{ this.isJoinclass.classname }},前去学习
      </div>
      <div v-else class="systeminfo" @click="joinclass()">
        您未加入任何班级，点击加入
      </div>

      <div class="editback" @click="toLoginout()">退出系统</div> -->
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      //   title: '去学习'
    }
  },
  props: ['userInfo'],
  computed: {
    isJoinclass: {
      get () {
        return this.$store.state.isJoinclass
      },
      set () { }
    },
    title: {
      get () {
        if (this.isJoinclass.classid > 0 && !this.isJoinclass.isgrauted) {
          return '去学习，您已报名 ' + this.isJoinclass.classname
        } else {
          return '先去报名吧'
        }
      },
      set () { }
    }
  },
  methods: {
    joinclass () {
      this.$router.push('/joinclass')
    },
    toCourses () {
      localStorage.setItem('getWith', 'classid')
      this.$router.push('/buddhism')
    },
    toLoginout () {
      var ok = confirm('确认要注销吗?')
      if (ok) {
        // this.$msg('退出成功')
        this.$msg({ message: '退出成功' })
        localStorage.clear()
        this.$store.commit('ClearState')
        this.$router.push('/login')
      }
    },
    toAction () {
      this.$msg('正在建设中，敬请期待。')
    },
    toclockinindex () {
      if (this.isJoinclass.classid > 0 && !this.isJoinclass.isgrauted) {
        this.$router.push('/clockinindex')
      } else {
        this.$msg('先去报名吧')
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.item {
  //   width: 100%;
  //   padding: 2.778vw;
  font-size: 3.611vw;
  text-align: center;
  //   background-color: chocolate;
}
.vancell {
  border-top: 0.278vw #ccc solid;
  margin-top: 4.167vw;
  margin-bottom: 2.778vw;
}
::v-deep .van-cell__title {
  color: chocolate;
}
.grid {
  margin-top: 4.167vw;
}
.cell-group-background {
  margin: 1.389vw;
}
</style>
