<template>
  <div id="content" :style="backgroundDiv">
    <Userinfo :userInfo="this.$store.state.userInfo"></Userinfo>
    <Pannel></Pannel>
  </div>
</template>

<script>
import Userinfo from '@/components/userComponent/UserInfo'
import Pannel from '@/components/userComponent/Pannel'
// import { mapState } from 'vuex'
export default {
  data () {
    return {
      backgroundDiv: {
        backgroundImage: 'url(' + require('@/assets/mine.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
      }
    }
  },
  beforeMount () {
    this.$store.commit('hideShowTabbar', true)
  },
  mounted () {
    // console.log(classid, 'classid')
    // // if (!Number(classid)) {
    const uid = localStorage.getItem('uid')
    // const s = localStorage.getItem('ddd')
    // console.log(s, 'classid')
    this.$store.dispatch('isJoinclass', {
      uid: uid
    })

    // console.log(this.isJoinclass, 'jj')
    // }
    if (this.$store.state.userInfo.length === 0) {
      this.$store.dispatch('getUserInfoAction', {
        uid: uid
      })
    }
    // if (!localStorage.getItem('configList')) {
    //   this.$store.dispatch('getConfigsDataAction')
    // }
    // console.log(this.$store.state.userInfo.length, 'mine web')
  },

  components: {
    Userinfo, Pannel
  }
}
</script>

<style lang="scss" scoped>
#content {
  text-align: center;
  margin: auto;
  width: 100%;
  height: 188.889vw;
}
.btn {
  border-radius: 20px;
  background-color: chocolate;
  color: rgb(255, 255, 255);
  width: 90%;
  text-align: center;
  margin: 5px auto;
  padding: 5px;
  font-size: 13px;
}

.editback {
  margin-top: 5.556vw;
  width: 100%;
  border-radius: 20px;
  background-color: #ff0000;
  //   display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  padding: 4.167vw 0;
  font-size: 4vw;
}
.systeminfo {
  margin-top: 5.556vw;
  width: 100%;
  border-radius: 20px;
  background-color: #c7c7d1;
  //   display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #524f4a;
  padding: 4.167vw 0;
  font-size: 4vw;
}
::v-deep .van-tabbar-item {
  width: 25%;
}
</style>
