<template>
  <div id="contentd" :style="backgroundDiv">
    <div class="bgimg">
      <!-- <img width="100%" src="@/assets/my.png" alt="" /> -->
      <div class="info">
        <div style="height: 12.556vw"></div>
        <van-row>
          <van-col span="10">
            <van-image round width="20vw" height="20vw" :src="userInfo.pic" />
          </van-col>
          <van-col span="14"
            ><div class="name">{{ userInfo.name }}</div></van-col
          >
        </van-row>
        <van-row>
          <van-col span="10">
            <div class="lv" v-if="userInfo.order">
              ORD：{{ userInfo.order }} / {{ userInfo.same }}
            </div>
            <div class="lv" v-else>LV：999 / 1</div>
          </van-col>
          <van-col span="14">
            <div class="lv" v-if="userInfo.score">
              总分：{{ userInfo.score }}
            </div>
            <div class="lv" v-else>总分：1000</div>
          </van-col>
        </van-row>
        <van-row>
          <van-col span="12">
            <div class="fhzhi">
              <img src="@/assets/hz.png" width="50vw" alt="" />
              <div class="number" v-if="userInfo.wisdom">
                {{ userInfo.wisdom }}
              </div>
              <div class="number" v-else>500</div>
            </div>
          </van-col>
          <van-col span="12">
            <div class="fhzhi">
              <img src="@/assets/fz.png" width="50vw" alt="" />
              <div class="number" v-if="userInfo.ford">
                {{ userInfo.ford }}
              </div>
              <div class="number" v-else>500</div>
            </div>
          </van-col>
        </van-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      backgroundDiv: {
        backgroundImage: 'url(' + require('@/assets/my.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%'
        // backgroundPosition: center center,
      },
      default: '@/assets/userinfo.png'
    }
  },
  props: ['userInfo'],
  mounted () {
    console.log(this.userInfo)
  }
}

</script>

<style lang="scss" scoped>
#contentd {
  height: 60vw;
  width: 100%;
}
.bgimg {
  width: 100%;
  height: 55.556vw;
  .info {
    width: 100%;
    top: 10vw;
    .name {
      font-size: 4.167vw;
      height: 8.333vw;
      padding-top: 8vw;
      text-align: left;
    }
  }
  .lv {
    padding-left: 4vw;
    font-size: 3.611vw;
    color: black;
    padding-top: 1.389vw;
    text-align: center;
    color: brown;
  }
  .fhzhi {
    // padding-left: 5.333vw;
    padding-top: 1vw;
    text-align: left;
    // background-color: rgb(107, 150, 106); van-tabbar-item
    img {
      width: 7.333vw;
      padding: none;
      margin: 0;
      //   padding-top: 1vw;
      padding-left: 14vw;
      //   background-color: rgb(119, 107, 89);
    }
    .number {
      font-size: 3.611vw;
      color: brown;
      //   line-height: 30px;
      vertical-align: top;
      text-align: left;
      padding-left: 15vw;
      //   background-color: rgb(119, 107, 89);
    }
  }
}

.cell-group-background {
  margin: 1.389vw;
}
</style>
